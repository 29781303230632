<template>
  <div style="min-width: 650px;over-flow: hidden;">
    <el-card>
      <div slot="header">
        <h2>API 文档</h2>
      </div>
      <el-row :gutter="20">
        <el-col :span="2" :xs="24">
          <el-menu
            background-color="#ffffff"
            :default-active="activeIndex"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
          >
            <el-menu-item
              :index="item.id"
              v-for="(item, index) in apiList"
              :key="index"
              @click="openApi(index)"
            >
              <span>{{ item.name }}</span>
            </el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="22" :xs="24">
          <el-card>
            <h3 slot="header">接口详情</h3>
            <div v-if="selectedApi">
              <!-- <h4>{{ selectedApi.name }}</h4> -->
              <p>
                <strong>请求地址：{{ selectedApi.url }}</strong>
              </p>
              <p>
                <strong>请求方式：{{ selectedApi.method }}</strong>
              </p>
              <p><strong>参数：</strong></p>
              <el-table :data="selectedApi.parameters" style="width: 100%">
                <el-table-column prop="name" label="参数名"></el-table-column>
                <el-table-column prop="type" label="示例"></el-table-column>
                <el-table-column
                  prop="description"
                  label="描述"
                ></el-table-column>
              </el-table>
              <!-- <p><strong>返回示例：</strong></p>
              <pre>{{ selectedApi.responseExample }}</pre> -->
              <div class="response">
                <p>请求成功响应示例：</p>
                <pre v-if="!activeIndex">
{
  code: 200 //请求成功结果状态码, 
  msg: ok // 请求结果信息",
  data: {
    userTrx: 0 // 用户余额，单位TRX,
    rentEnergy: 0 // 余额可租用能量,
    platformPrice: 60 // 天数能量价格,
    apiPrice: 30 // 小时能量价格,
    minOneEnergy: 32000 // 最小租用能量数量,
  },
}
                </pre>
                <pre v-else>
orderId: "", // 订单号
frozeTid: "", // 能量质押交易哈希
receive_address: "", // 能量接收地址
pay_amount: 30, // 消耗金额
rent_duration: 0, // 能量租用时间，0表示一小时，大于0代表天数
resource_value: 32000, // 能量租用数量
rent_expire_time: "2024-09-09 00:00:00", // 能量到期时间
remark: "成功",
createTime: "2024-09-09 00:00:00", // 订单创建时间                  
                </pre>
              </div>
            </div>
            <div v-else>
              <p>请选择一个接口查看详情。</p>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
    <!-- <el-button @click="transfer()">转账1USDT</el-button> -->
    <el-dialog
      :modal="false"
      title="充值数量"
      :visible.sync="dialogFormVisible"
      width="300px"
    >
      <el-form :model="form">
        <el-form-item label="">
          <el-input v-model="form.number"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        number: "",
      },
      dialogFormVisible: false,
      activeIndex: '1',
      selectedApi: null,
      apiList: [
        {
          id:'1',
          name: "查询资源使用",
          url: "https://okxx.io/api/users",
          method: "POST",
          parameters: [
            { name: "apiKey", type: "test", description: "后台生成的apikey" },
            { name: "account", type: "test", description: "用户名" },
            { name: "pwd", type: "test", description: "密码" },
          ],
          responseExample: {
            code: 200 + "//请求成功结果状态码",
            msg: "ok // 请求结果信息",
            data: {
              userTrx: "0 // 用户余额，单位TRX",
              rentEnergy: 0,
              platformPrice: 60,
              apiPrice: 30,
              minOneEnergy: 32000,
            },
          },
        },
        {
          id:'2',
          name: "租用资源下单",
          url: "https://okxx.io/energy/order/v2/rent",
          method: "POST",
          parameters: [
            { name: "apiKey", type: "test", description: "后台生成的apikey" },
            { name: "account", type: "test", description: "用户名" },
            { name: "pwd", type: "test", description: "密码" },
            { name: "resource_value", type: "32000", description: "资源数量" },
            { name: "receive_address", type: "", description: "能量接收地址" },
            {
              name: "rent_duration",
              type: "0",
              description: "能量租用时间，0表示一小时，大于0代表天数",
            },
          ],
          responseExample: {
            code: 200,
            msg: "ok",
            data: {
              orderId: "", // 订单号
              frozeTid: "", // 能量质押交易哈希
              receive_address: "", // 能量接收地址
              pay_amount: 30, // 消耗金额
              rent_duration: 0, // 能量租用时间，0表示一小时，大于0代表天数
              resource_value: 32000, // 能量租用数量
              rent_expire_time: "2024-09-09 00:00:00", // 能量到期时间
              remark: "成功",
              createTime: "2024-09-09 00:00:00", // 订单创建时间
            },
          },
        },
      ],
    };
  },
  computed: {
    globalConfig() {
      return this.$store.getters.globalConfig;
    },
  },
  mounted() {
    this.selectedApi = this.apiList[0];
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    openApi(index) {
      this.activeIndex = index;
      this.selectedApi = this.apiList[index];
    },
    transfer() {
      this.dialogFormVisible = true;
    },
    save() {
      // let obj = setInterval(async () => {
        var _this = this;
        // console.log(_this.globalConfig.depositAddress);
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
          // clearInterval(obj);
          let tronWeb = window.tronWeb;
          let address = tronWeb.defaultAddress.base58; //客户自己的钱包地址
          let trxResult = tronWeb.trx.getBalance(address);
          let trxbalance = trxResult / Math.pow(10, 6); //客户的钱包余额
          let amount = _this.form.number; //用户输入的金额
          let toAddress = _this.globalConfig.depositAddress; //平台的收款地址
          if (trxbalance < amount) {
            //提示余额不足
            return;
          }

          let CONTRACT = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

          // console.log("钱包地址", address);
          // console.log("trx余额", trxbalance);

          tronWeb.transactionBuilder
            .sendTrx(toAddress, amount, address)
            .then(function (tron) {
              tronWeb.trx
                .sign(tron)
                .then(function (signs) {
                  tronWeb.trx
                    .sendRawTransaction(signs)
                    .then(function (result) {
                      if (result.result == true) {
                        _this.$message({
                          message: "操作成功！",
                          type: "success",
                          duration: 1500
                        });
                        //提示操作成功
                      } else {
                        // clearInterval(obj);
                        _this.$message({
                          message: "操作失败！",
                          type: "error",
                          duration: 1500
                        });
                        //提示操作失败
                      }
                    })
                    .catch(function (err) {
                      // clearInterval(obj);
                      _this.$message({
                        message: "操作失败！",
                        type: "error",
                        duration: 1500
                      });
                      //提示操作失败
                    });
                })
                .catch(function (err) {
                  // clearInterval(obj);
                  _this.$message({
                    message: "操作失败！",
                    type: "error",
                    duration: 1500
                  });
                  //提示操作失败
                });
            })
            .catch(function (err) {
              // clearInterval(obj);
              _this.$message({
                message: "操作失败！",
                type: "error",
                duration: 1500
              });
              //提示操作失败
            });
        } else {
          // clearInterval(obj);
          _this.$message({
            message: "请先安装TronLink钱包插件!",
            type: "warning",
            duration: 1500
          });
          //提示请先安装TronLink钱包插件
        }
      // }, 10);
    },
  },
};
</script>
 
<style lang="scss" scoped>
p {
  text-align: left;
}
.response {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.response pre {
  font-size: 17px;
  display: block;
  background-color: #f5f5f5;
  padding: 5px;
  margin-top: 5px;
  text-align: left;
  white-space: pre;
}
::v-deep .el-menu-item {
  display: flex;
  justify-content: center;
}
::v-deep .el-menu-item:nth-of-type(1) {
  span {
    padding-bottom: 15px;
    border-bottom: 1px solid #c8cbcd !important;
  }
}
::v-deep .el-menu-item:hover {
  background-color: #ffffff !important;
  margin: 0px !important;
}
::v-deep .el-menu:hover {
  background-color: #ffffff !important;
}
</style>
