import request from './index'
import {
  encryptCode
} from './common.js'
import Cookies from 'js-cookie';
/**
 * 事实模型配置列表查询
 */
export function getfactInfoList(data) {
  return request({
    url: `/api/metadata/factInfo/search`,
    method: 'post',
    data
  })
}


export function getConfig(data) {
  return request({
    url: `/energy/config`,
    method: 'post',
    data: {
      data: encryptCode({
        a: 1
      })
    }
  })
}

/* 注册 */
export function register(data) {
  return request({
    url: `/energy/register`,
    method: 'post',
    data: {
      data: encryptCode({
        account: data.account,
        pwd: data.pwd
      })
    }
  })
}
/* 登录 */
export function login(data) {
  return request({
    url: `/energy/login`,
    method: 'post',
    data: {
      data: encryptCode({
        account: data.account,
        pwd: data.pwd
      })
    }
  })
}

/* 登出 */
export function logout(data) {
  return request({
    url: `/energy/logout`,
    method: 'post',
    data
  })
}

/* 查询用户信息 */
export function userInfo() {
  return request({
    url: `/energy/user/info`,
    method: 'post',
    data: {
      data: encryptCode({
        token: Cookies.get('isToken')
      }),
      token: Cookies.get('isToken')
    }
  })
}

/* 修改密码 */
export function changeLoginPwd(data) {
  return request({
    url: `/energy/changeLoginPwd`,
    method: 'post',
    data: {
      data: encryptCode({
        oldPwd: data.oldPwd,
        pwd: data.pwd
      })
    }
  })
}

/* 立即租用 */
export function buy(data) {
  return request({
    url: `/energy/account/buy`,
    method: 'post',
    data: {
      data: encryptCode({
        address: data.address,
        energyNum: data.energyNum,
        sun: data.sun,
        times: data.times
      })
    }
  })
}

/* 公告 */
export function notice(data) {
  return request({
    url: `/energy/notice`,
    method: 'post',
    data: {
      data: encryptCode({
        a: 1
      })
    }
  })
}

/* 成交订单列表 */
export function sellOrder(data) {
  return request({
    url: `/energy/sellOrder`,
    method: 'post',
    data: {
      data: encryptCode({
        a: 1
      })
    }
  })
}

/* 查询小时代理配置 */
export function hourInfo(data) {
  return request({
    url: `/energy/hour/config/info`,
    method: 'post',
    data: {
      data: encryptCode({
        a: 1
      })
    }
  })
}

/* 修改小时代理配置 */
export function hourEdit(data) {
  return request({
    url: `/energy/hour/config/save`,
    method: 'post',
    data: {
      data
    }
  })
}
export function hourSave(data) {
  return request({
    url: `/energy/hour/config/add`,
    method: 'post',
    data: {
      data
    }
  })
}

/* 查询包月代理配置 */
export function monthInfo(data) {
  return request({
    url: `/energy/month/config/info`,
    method: 'post',
    data: {
      data: encryptCode({
        a: 1
      })
    }
  })
}

/* 保存小时代理配置 */
export function monthSave(data) {
  return request({
    url: `/energy/month/config/save`,
    method: 'post',
    data: {
      data
    }
  })
}

/* 查询笔数代理配置*/
export function oneInfo(data) {
  return request({
    url: `/energy/one/config/info`,
    method: 'post',
    data: {
      data: encryptCode({
        a: 1
      })
    }
  })
}

/* 保存笔数代理配置 */
export function oneSave(data) {
  return request({
    url: `/energy/one/config/save`,
    method: 'post',
    data: {
      data
    }
  })
}

/* 笔数客户列表*/
export function oneLlist(data) {
  return request({
    url: `/energy/one/user/list`,
    method: 'post',
    data: {
      data: encryptCode(data)
    }
  })
}

/* 查询笔数代理配置*/
export function oneOpen(data) {
  return request({
    url: `/energy/one/user/open`,
    method: 'post',
    data: {
      data: encryptCode(data)
    }
  })
}

/* 生成apiKey */
export function apikeyAdd(data) {
  return request({
    url: `/energy/apikey/add`,
    method: 'post',
    data: {
      data: encryptCode({
        a: 1
      })
    }
  })
}

/* 绑定用户钱包 */
export function wallet(data) {
  return request({
    url: `/energy/bind/wallet`,
    method: 'post',
    data: {
      data
    }
  })
}

/* 统计小时代理数据 */
export function hourData(data) {
  return request({
    url: `/energy/hour/data`,
    method: 'post',
    data: {
      data: encryptCode({
        a: 1
      })
    }
  })
}

/* 统计包月代理数据 */
export function monthData(data) {
  return request({
    url: `/energy/month/data`,
    method: 'post',
    data: {
      data: encryptCode({
        a: 1
      })
    }
  })
}

/* 查询小时代理订单 */
export function hourRecord(data) {
  return request({
    url: `/energy/hour/record`,
    method: 'post',
    data: {
      data: encryptCode(data)
    }
  })
}

/* 查询包月代理订单 */
export function monthRecord(data) {
  return request({
    url: `/energy/month/record`,
    method: 'post',
    data: {
      data: encryptCode(data)
    }
  })
}

/* 用户订单 */
export function energyRecord(data) {
  return request({
    url: `/energy/user/energy/record`,
    method: 'post',
    data: {
      data: encryptCode(data)
    }
  })
}

/* 后台用户数据 */
export function userData(data) {
  return request({
    url: `/energy/user/data`,
    method: 'post',
    data: {
      data: encryptCode({
        a: 1
      })
    }
  })
}

/* 资金明细 */
export function moneyRecord(data) {
  return request({
    url: `/energy/user/money/record`,
    method: 'post',
    data: {
      data: encryptCode(data)
    }
  })
}

/* 处理失败订单-小时 */
export function hourDeal(data) {
  return request({
    url: `/energy/hour/deal`,
    method: 'post',
    data: {
      data: encryptCode({
        a: 1
      })
    }
  })
}

/* 处理失败订单-包月 */
export function monthDeal(data) {
  return request({
    url: `/energy/month/deal`,
    method: 'post',
    data: {
      data: encryptCode({
        a: 1
      })
    }
  })
}

/* 折线图数据 */
export function dataList(data) {
  return request({
    url: `/energy/user/dataList`,
    method: 'post',
    data: {
      data: encryptCode({
        a: 1
      })
    }
  })
}