<template>
  <div class="transfer-rent">
    <announcement></announcement>
    <div style="width: 100%;display: flex;flex-wrap: wrap;">
      <div class="left" v-if="pageSize != 'mini'">
        <el-card>
          <div style="padding: 63px 20px 120px 20px;display: flex;justify-content: space-between;">
            <div>
              <p style="margin-top: 20px;">100,000 能量，租金仅需</p>
              <p style="margin-top:30px;font-size: 30px;font-weight: 700;line-height: 41px;">≈ {{(100000 * 0.000001 * globalConfig.platformPrice).toFixed(2) }}TRX/天</p>
              <p style="margin-top:30px;font-size: 30px;font-weight: 700;line-height: 41px;">≈ {{(100000 * 0.000001 * globalConfig.apiPrice).toFixed(2) }}TRX/一小时</p>
            </div>
            <img v-if="pageSize != 'mini'" style="width: 200px;" src="@/assets/img/energy-icon.png" alt="">
          </div>
          <div style="background: #3d3f4614;padding: 24px 20px;border-radius: 8px;margin-bottom: 20px;">
            <div style="margin-bottom: 30px;font-size: 16px;font-weight: 600;">对比其他能量获取途径</div>
            <div style="display: flex;justify-content: space-between;">
              <div style="flex:1">
                <!-- <img src="" alt=""> -->
                <p style="font-size: 12px;margin-bottom: 16px;">获取 100,000 能量需质押</p>
                <p><span style="font-size: 18px;font-weight: 600">{{ toThousandth(Math.ceil(100000 / globalConfig.trxEnergy)) }}</span> TRX</p>
              </div>
              <div style="flex:1">
                <!-- <img src="" alt=""> -->
                <p style="font-size: 12px;margin-bottom: 16px;">获取 100,000 能量需燃烧</p>
                <p><span style="font-size: 18px;font-weight: 600;">42</span> TRX</p>
              </div>
            </div>
          </div>
        </el-card>
      </div>

      <div class="right">
        <el-card>
          <div slot="header" class="clearfix">
            <span>租用能量</span>
          </div>
          <div>
            <div style="text-align: right; font-size: 12px;">可用余额: {{ userInfo.balance }} TRX</div>
            <div>
            <el-form :model="form" ref="form" :rules="rules">
              <div style="margin-bottom: 16px;">
                <div style="font-size: 14px;color: #606266;margin-bottom: 5px;">租用地址
                  <el-tooltip class="item" effect="dark" content="实际到账能量地址" placement="bottom">
                    <i class="el-icon-question" style="color:#909399"></i>
                  </el-tooltip>
                </div>
                <el-form-item prop="address">
                  <el-input v-model="form.address" placeholder="租用地址" style="width: 100%;"></el-input>
                </el-form-item>
              </div>
              <div style="margin-bottom: 16px;">
                <div style="font-size: 14px;color: #606266;margin-bottom: 5px;">租用数量
                  <el-tooltip class="item" effect="dark" content="实际到账能量由当前市场可用量決定，可能会有一定误差。能量使用后会持续恢复， 24 小時会恢复到 100%，请根据您的每日操作所需填写" placement="bottom">
                    <i class="el-icon-question" style="color:#909399"></i>
                  </el-tooltip>
                </div>
                <el-form-item prop="energyNum">
                  <el-input v-model="form.energyNum" placeholder="租用数量" style="width: 100%;"></el-input>
                </el-form-item>
              </div>
              <div style="margin-bottom: 16px;">
                <div style="font-size: 14px;color: #606266;margin-bottom: 5px;">时长/天(小时)
                  <el-tooltip class="item" effect="dark" content="租用时长將影响您的租金。租用到期后系统会自动回收您的能量(输入0是冻结1小时请悉知)" placement="bottom">
                    <i class="el-icon-question" style="color:#909399"></i>
                  </el-tooltip>
                </div>
                <el-form-item prop="times">
                  <el-input v-model="form.times" placeholder="时长/天(小时)" style="width: 100%;"></el-input>
                </el-form-item>
              </div>
            </el-form>
            </div>
            <div style="text-align: right;font-size: 12px;">输入 0 是冻结1小时,请悉知!</div>
            <div style="text-align: right;font-size: 12px;">预计 {{ endTime }} 到期被系統回收，具体时间会根据市场情況发生变化</div>
            <div style="background: #3d3f4614;padding: 16px 20px;border-radius: 8px;margin-top:10px">
              <div style="display: flex;justify-content: space-between;border-bottom: 1px solid #dcdfe6;padding-bottom: 10px;">
                <p>总额：</p>
                <p style="font-size: 16px;font-weight: 700;">{{ rental }} TRX</p>
              </div>
              <div style="display: flex;justify-content: space-between;margin-top: 10px;">
                <p>节约：</p>
                <p style="font-size: 16px;font-weight: 700;">{{ economy }} TRX</p>
              </div>
              <div style="display: flex;justify-content: space-between;margin-top: 10px;">
                <p>节约比例：</p>
                <p style="font-size: 16px;font-weight: 700;">{{ savingRatio }} %</p>
              </div>
              <div style="display: flex;justify-content: space-between;margin-top: 10px;">
                <p>手续费：</p>
                <p style="font-size: 16px;font-weight: 700;">0 TRX</p>
              </div>
            </div>
            <el-button type="warning" size="mini" style="width: 100%;margin:20px 0 18px 0" @click="charter">立即租用</el-button>
          </div>
        </el-card>
      </div>
    </div>
    
    <el-card style="width: 100%;margin: 20px 15px 0 15px">
      <div slot="header">
        <span class="card-title">市场数据</span>
      </div>
      <div style="border-bottom: 1px solid #dcdfe6;padding-bottom: 20px;">
        <div>租用能量单价</div>
        <div style="margin: 15px 0 "><span style="font-size: 26px;font-weight: 700;margin-right: 5px;">{{ globalConfig.platformPrice }}</span>sun/天</div>
        <div>注：1 TRX = 1,000,000 sun</div>
      </div>
      <div style="padding: 20px 0;display: flex;flex-wrap: wrap;">
        <div  style="margin-right: 80px;">
          <div>市场总能量</div>
          <div style="margin: 15px 0 "><span style="font-size: 26px;font-weight: 700;margin-right: 5px;">{{ toThousandth(globalConfig.totalEnergyNum) }}</span>能量</div>
        </div>
        <div>
          <div>市场可租用能量</div>
          <div style="margin: 15px 0 "><span style="font-size: 26px;font-weight: 700;margin-right: 5px;">{{ toThousandth(globalConfig.leftEnergyNum) }}</span>能量</div>
        </div>
      </div>
    </el-card>

    <el-card style="width: 100%;margin: 20px 15px 0 15px">
      <div slot="header">
        <span class="card-title">订单列表</span>
      </div>
       <!-- 订单列表表格 -->
       <el-table
        :data="orderList"
        style="width: 100%">
        <el-table-column
          prop="money"
          label="收入最高">
        </el-table-column>
        <el-table-column
          prop="sun"
          label="收入单价">
        </el-table-column>
        <el-table-column
          prop="单价"
          label="单价">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="租用时长">
        </el-table-column>
        <el-table-column
          label="操作">
        </el-table-column>
      </el-table>
      <el-pagination
        style="text-align: right;margin-top: 20px;"
        background
        :current-page="orderPage.current"
        layout="prev, pager, next"
        @prev-click="prevClick"
        @next-click="nextClick"
        @current-change="currentChange"
        :total="orderPage.total">
      </el-pagination>
    </el-card>

    <el-card style="width: 100%;margin: 20px 15px">
      <div slot="header">
        <span class="card-title">质押详情</span>
      </div>
      <el-tabs activeName="first">
        <el-tab-pane label="最近完成" name="first">
          <el-table
            :data="orderSuccessList"
            style="width: 100%">
            <el-table-column
              label="订单信息"
              min-width="25%">
              <template slot-scope="scope">
                <p>单价：{{ scope.row.sun }} sun</p>
                <p>能量：{{ toThousandth(scope.row.energyNum) }}</p>
              </template>
            </el-table-column>
            <el-table-column
              label="收入"
              min-width="25%">
              <template slot-scope="scope">
                <span style="color:#409EFF">{{ scope.row.money }}</span> TRX
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              min-width="25%"
              label="时间">
            </el-table-column>
            <el-table-column
              prop="frozenHash"
              width="100px"
              label="操作">
              <template slot-scope="scope">
                <el-button type="success" size="mini" plain @click="hxDeal(scope.row.tid)">交易哈希</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <!-- <el-tab-pane label="购买订单" name="second">
          <el-table
            :data="myOrderList"
            style="width: 100%">
            <el-table-column
              prop="订单信息"
              label="订单信息">
            </el-table-column>
            <el-table-column
              prop="价格"
              label="价格">
            </el-table-column>
            <el-table-column
              prop="接受地址"
              label="接受地址">
            </el-table-column>
            <el-table-column
              prop="时间"
              label="时间">
            </el-table-column>
            <el-table-column
              prop="状态"
              label="状态">
            </el-table-column>
            <el-table-column
              width="160"
              label="操作">
            </el-table-column>
          </el-table>
          <el-pagination
            style="text-align: right;margin-top: 20px;"
            background
            :current-page="myOrderPage.current"
            layout="prev, pager, next"
            @prev-click="myOrderPrevClick"
            @next-click="myOrderNextClick"
            @current-change="myOrderCurrentChange"
            :total="myOrderPage.total">
          </el-pagination>
        </el-tab-pane> -->

      </el-tabs>
    </el-card>

    
  </div>
</template>

<script>
import { buy,sellOrder } from '@/api/login';
import eventBus from '@/utils/eventBus';
import announcement from "./announcement.vue"
import moment from 'moment'
import Cookies from 'js-cookie';
export default {
  name: 'TransferRentView',
  components: {
    announcement
  },
  props: {
    pageSize: {
      type: String, 
      default: 'medium'
    }
  },
  data() {
    var validateEnergyNum = (rule, value, callback) => {
      // let regex = /^(100000|[1-9]\d{5})$/;
      if (!value) {
        callback(new Error('请输入租用数量'));
      }else if(Number(value)<32000) {
        callback(new Error('租用数量不小于32000！'));
      } else {
        callback();
      }
    };
    var validateTimes = (rule, value, callback) => {
      let regex = /^(0|[1-9]\d*)$/;
      if (!value) {
        callback(new Error('请输入租用时常'));
      }else if(!(regex.test(value))) {
        callback(new Error('时长只能为0或正整数！'));
      }else {
        callback();
      }
    };
    return {
      savingRatio: 0,
      form: {
        // 租用地址
        address: '',
        // 租用数量
        energyNum: 100000,
        // 时长
        times: 3,
      },
      // 市场总能量
      total: 2518933892,
      // 订单列表
      orderList: [],
      orderPage: {
        current:1,
        size: 10,
        total: 0
      },
      // 最近完成
      orderSuccessList: [],
      // 购买订单
      myOrderList: [],
      myOrderPage: {
        current:1,
        size: 10,
        total: 0
      },
      tronWebInterval: null,
      userAddress: '',
      rules: {
        energyNum: [
          { validator: validateEnergyNum, trigger: 'blur' },
        ],
        times: [
          { validator: validateTimes, trigger: 'blur' },
        ],
      },
      orderTime: null
    }
  },
  methods: {
    //交易哈希
    hxDeal(id) {
      window.open("https://tronscan.org/#/transaction/" + id);
    },
    charter() {
      let token = Cookies.get('isToken');
      if(token) {
        let { apiPrice,platformPrice } = this.globalConfig;
        let { address,energyNum,times } = this.form;
        this.$refs.form.validate((valid) => {
          if (valid) {
            var data = {
              address,
              energyNum,
              sun: this.times == 0 ? apiPrice : platformPrice,
              times
            }
            buy(data).then(res =>{
              let { code,error } = res.data;
              if(code == 200) {
                this.$message({
                  message: "租用成功！",
                  type: "success",
                  duration: 1500
                });
              }
            })
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
      } else {
        // this.$message({
        //   message: '请先登录！',
        //   type: 'warning'
        // });
        eventBus.$emit('notToken','buy');
      }
    },
    toThousandth(num) {
      const str = num +''
      const newStr = str.split('').reverse().join('').replace(/(\d{3})+?/g, function(s){
        return s+',';
      }).replace(/,$/, '').split('').reverse().join('')
      return newStr
    },
    currentChange() {

    },
    // 上一页
    prevClick() {
      
    },
    // 下一页
    nextClick() {

    },
    // 获取最近完成列表信息
    getOrderSuccess() {
      //最近完成
      sellOrder().then(res =>{
        let { code,data } = res.data;
        if(code == 200) {
          this.orderSuccessList = data.list;
        }
      })
      // this.orderSuccessList = [
      //   {
      //     "trxNumber": 0,
      //     "energyNum": 100000,
      //     "addTime": "2024-03-03 09:52:20",
      //     "price": 7,
      //     "resourceState": 1,
      //     "sun": 70,
      //     "frozenHash": "9611146d72cc6fb48851e88d4f11b755169f67da6e9a09546f1b4610c0515b0c"
      //   },
      //   {
      //     "trxNumber": 0,
      //     "energyNum": 420000,
      //     "addTime": "2024-03-03 04:55:48",
      //     "price": 29.4,
      //     "resourceState": 1,
      //     "sun": 70,
      //     "frozenHash": "ae9e0f195744b696eb540fb63f625586a3a05804f8b2875d82e55cab0bd347d2"
      //   },
      //   {
      //     "trxNumber": 0,
      //     "energyNum": 300000,
      //     "addTime": "2024-03-03 04:51:48",
      //     "price": 21,
      //     "resourceState": 1,
      //     "sun": 70,
      //     "frozenHash": "8ffaefcd37e9e7035170e9a8e4a302d31c175c8353903ed7d684e4192517395a"
      //   },
      //   {
      //     "trxNumber": 0,
      //     "energyNum": 300000,
      //     "addTime": "2024-03-03 02:43:01",
      //     "price": 21,
      //     "resourceState": 1,
      //     "sun": 70,
      //     "frozenHash": "88ab7530a0a36f4fb3d25a03deb86ac499d2637a773624c24ceae6df955d27ff"
      //   },
      //   {
      //     "trxNumber": 0,
      //     "energyNum": 100000,
      //     "addTime": "2024-03-03 00:52:54",
      //     "price": 7,
      //     "resourceState": 1,
      //     "sun": 70,
      //     "frozenHash": "f870843da4aa394910f63f5dce777ffca1845fae702f2616b850a8d2004d0617"
      //   },
      //   {
      //     "trxNumber": 0,
      //     "energyNum": 100000,
      //     "addTime": "2024-03-03 00:01:51",
      //     "price": 7,
      //     "resourceState": 1,
      //     "sun": 70,
      //     "frozenHash": "ad7eaa7de83c88d81783f10c9a55e2f5950730a7e7cad0d9b761638ead55268c"
      //   },
      //   {
      //     "trxNumber": 0,
      //     "energyNum": 170000,
      //     "addTime": "2024-03-02 22:46:18",
      //     "price": 11.9,
      //     "resourceState": 1,
      //     "sun": 70,
      //     "frozenHash": "376e237f5cd8b7350dd8686b8d9ce1c2c555248e6b7a3d483cf8b3247589b7ee"
      //   },
      //   {
      //     "trxNumber": 0,
      //     "energyNum": 1500000,
      //     "addTime": "2024-03-02 21:56:10",
      //     "price": 105,
      //     "resourceState": 1,
      //     "sun": 70,
      //     "frozenHash": "75defe8825251e31ecefacf1c1bea52473218acb8474d159dbe9356cf422e983"
      //   },
      //   {
      //     "trxNumber": 0,
      //     "energyNum": 1700000,
      //     "addTime": "2024-03-02 21:39:22",
      //     "price": 119,
      //     "resourceState": 1,
      //     "sun": 70,
      //     "frozenHash": "28b504abcd754c86a569651364231c5af7e7d3840e2c07470b0b3ffea4ecffa1"
      //   },
      //   {
      //     "trxNumber": 0,
      //     "energyNum": 100000,
      //     "addTime": "2024-03-02 21:26:55",
      //     "price": 7,
      //     "resourceState": 1,
      //     "sun": 70,
      //     "frozenHash": "0943c7b81fc986afb9610348353473386e6d761d0b264d45d723930602c8355a"
      //   }
      // ]
    },
    // 获取购买订单列表
    getMyOrder() {
      this.myOrderList = []
      this.myOrderPage = {
        current:1,
        size: 10,
        total: 0
      }
    },
    //获取订单列表
    getSellOrder() {
      
    },
    myOrderCurrentChange() {

    },
    myOrderPrevClick() {

    },
    myOrderNextClick() {

    },
  },
  created() {
    // 获取最近完成列表
    this.getOrderSuccess();
    this.orderTime = setInterval(()=>{
      this.getOrderSuccess();
    },5000)
    //获取订单列表
    // this.getSellOrder();

  },
  beforeDestroy() {
    clearInterval(this.orderTime);
  },
  computed: {
    globalConfig() {
      return this.$store.getters.globalConfig
    },
    userInfo() {
      let { username,balance } = this.$store.getters.userInfo;
      if(sessionStorage.getItem('isToken')) {
        return {
          balance: balance ? balance : 0,
          username
        }
      } else {
        return {
          balance: '--',
          username
        }
      }
    },
    endTime() {
        let time = '';
        if(this.form.times == 0) {
          time = moment().add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
        } else {
          time = moment().add(this.form.times, 'days').format('YYYY-MM-DD HH:mm:ss');
        }
        return time;
      },
    rental() {
       let rental = 0;
       let { apiPrice,platformPrice } = this.globalConfig;
       if(this.form.times == 0) {
          rental = this.form.energyNum * (apiPrice*0.000001)
        } else {
          rental = this.form.energyNum * (platformPrice*0.000001) * this.form.times
        }
        return rental.toFixed(2);
    },
    economy() {
      let nowMonely = 0;
      let { apiPrice,platformPrice } = this.globalConfig;
      if(this.form.times == 0) {
          nowMonely = this.form.energyNum / 32000 * 13.3959;
        } else {
          nowMonely = this.form.energyNum / 32000 * this.form.times * 13.3959;
        }
        this.savingRatio = (((nowMonely.toFixed(2) - this.rental) / nowMonely.toFixed(2))*100).toFixed(0)
      return (nowMonely.toFixed(2) - this.rental).toFixed(2);
    },
  },
  mounted() {
    this.tronWebInterval = setInterval(()=>{
    const tronWeb = window.tronWeb;
    //console.log(tronWeb.defaultAddress.base58)
    
    if (tronWeb && tronWeb.defaultAddress.base58) {
      clearInterval(this.tronWebInterval);
      this.tronWebInterval = null
      this.userAddress =  tronWeb.defaultAddress.base58
    }
  }, 5000)
    
  },
  watch: {
    userAddress: {
      handler: async (newVal) =>{
        if(newVal) {
          const tronWeb = window.tronWeb;
          let trxResult = await tronWeb.trx.getBalance(newVal);
          let trxbalance = trxResult / Math.pow(10, 6);
          // console.log(trxbalance)
        }
      },
      immediate: true
    }
  },
  destroyed() {
    clearInterval(this.tronWebInterval)
    this.tronWebInterval = null
  }
}
</script>

<style lang="scss" scoped>
  .transfer-rent{
    /**width: 90%;
    max-width: 1440px;**/
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    overflow: hidden;
    .left{
      flex: 1 1 690px;
      margin: 15px 15px 0 15px;
    }
    .right{
      flex: 1 1 690px;
      margin: 15px 15px 0 15px;
    }
    .card-title{
      font-size: 18px;
      position: relative;
      padding-left: 14px;
      &::before{
        top: 5px;
        left: 0;
        margin-top: 2px;
        content: "";
        display: inline-block;
        width: 5px;
        height: 12px;
        background-color: #50bfff;
        position: absolute;
      }
    }
  }
  ::v-deep .el-form-item {
    margin-bottom: 16px;
  }
  .right {
    ::v-deep .el-card__body {
      padding: 14px 20px;
    }
  }
</style>