import {Message} from 'element-ui';

// 复制接收地址
export const copy = (mesg) => {
    const input = document.createElement("input"); // 创建输入框元素   
    input.value = mesg; // 设置输入框的值为要复制的内容
    document.body.appendChild(input); // 将输入框添加到页面中
    input.select(); // 选中输入框中的内容
    if (document.execCommand('copy')) { // 如果复制成功
        Message.success('复制成功')
    } else { 
        Message.error(复制失败)
    }
    document.body.removeChild(input); // 移除输入框
}

// 订单类型
export const DDIC_ORDER_TYPE = [
    // { label: '能量', value: 'ENERGY', color:'#409eff'},
    // { label: '带宽', value: 'BANDWIDTH'},
    // { label: '充值', value: 'charge', color:'#67c23a'},
    // { label: '速充', value: 'speed'},
    // { label: '激活', value: 'activation'},
    // { label: '笔数', value: 'autoTypeOne'},
    // { label: '智能', value: 'autoTypeTwo'},
    // { label: '归集', value: 'imputation'},
    // { label: 'TG会员', value: 'tgvip'},
    // { label: '转租能量', value: 'rentEnergy'},
    // { label: '转租笔数', value: 'rentAutoTypeOne'}
    { label: '全部', value: 0},
    { label: '充值', value: 1},
    { label: 'api', value: 2},
    { label: '小时代理', value: 3},
    { label: '包月代理', value: 4},
    { label: '手动租用', value: 5},
    { label: '笔数委托', value: 6},
    { label: '笔数滞留', value: 7},
  ]