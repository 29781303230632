<template>
  <div class="user-home">
    <div class="top">
      <!-- <i class="el-icon-bell"></i>
      <div class="scrolling-text">
        <span>{{ noticeInfo }}</span>
      </div> -->
      <announcement></announcement>
    </div>

    <div class="top-box">
      <el-card style="flex: 1; flex-basis: 750px; margin: 10px">
        <div style="display: flex; flex-wrap: wrap; height: 100%">
          <div
            style="
              position: relative;

              flex: 1 1 355px;
              box-sizing: border-box;
              flex-direction: column;
              justify-content: space-between;
            "
            :style="{
              borderRight: pageSize == 'mini' ? 'none' : '2px dashed #e5e7eb;',
              paddingRight: pageSize == 'mini' ? 'none' : '20px',
            }"
          >
            <div style="display: flex; align-items: center">
              <img
                style="border-radius: 50%; margin-right: 30px"
                src="@/assets/img/photo.jpg"
                alt=""
              />
              <div>
                <p style="font-size: 30px; font-weight: 700">
                  {{ userInfo.username }}
                </p>
                <p>
                  <span
                    style="font-size: 20px; font-weight: 600; color: #e6a23c"
                    >{{ userInfo.balance }}</span
                  >
                  TRX
                </p>
              </div>
            </div>
            <div :style="{ height: pageSize == 'mini' ? '200px' : '361px' }">
              <el-alert
                :closable="false"
                title="API权限"
                style="margin: 20px 0 30px 0; height: 54px"
                type="success"
                description="可通过API请求下单"
                show-icon
              >
              </el-alert>
              <!-- <el-input
              type="textarea"
              :rows="2"
              placeholder="该项配置智能托管触发委托通过接口。POST请求"
              v-model="configer">
            </el-input> -->
              <el-input
                style="margintop: 8px"
                v-if="apiKey"
                v-model="apiKey"
                readonly
              >
              </el-input>
            </div>
            <el-button
              type="success"
              round
              style="width: calc(100% - 20px)"
              @click="apiKeySubmit"
              :loading="apiKeyLoading"
              >{{ apiKey ? "复制APIKEY" : "生成APIKEY" }}</el-button
            >
          </div>

          <div
            style="
              position: relative;
              padding-left: 20px;
              flex: 1 1 355px;
              box-sizing: border-box;
              flex-direction: column;
              justify-content: space-between;
            "
            :style="{ paddingLeft: pageSize == 'mini' ? '0px' : '20px' }"
          >
            <div
              style="font-size: 25px; font-weight: 700"
              :style="{ marginTop: pageSize == 'mini' ? '30px' : '0px' }"
            >
              充值
            </div>
            <div style="height: 334px">
              <el-alert
                :closable="false"
                style="margin: 50px 0 31px 0; height: 55px"
                type="success"
                description="使用绑定的地址转账会自动充值"
                show-icon
              >
              </el-alert>
              <!-- <el-input @change="rechargeChange" style="margin-top:100px" type="number" placeholder="请输入要充值的TRX数量，最小重置数量50Trx" v-model="recharge">
            </el-input> -->
              <div style="border: 1px dashed #E0E8F6;">
                <el-row :span="24">
                  <el-col :xs="24" :sm="24" :lg="24">
                    <span
                      style="
                        margin: 10px 0;
                        display: inline-block;
                        font-size: 12px;
                        text-align: center;
                        width: 100%;
                      "
                      >收款地址
                    </span>
                  </el-col>
                  <el-col :xs="24" :sm="24" :lg="24">
                    <span
                      style="
                        font-size: 12px;
                        text-align: center;
                        display: inline-block;
                        margin: 12px 0 10px 0;
                        width: 100%;
                      "
                    >
                      {{ globalConfig.depositAddress
                      }}<i
                        class="el-icon-document-copy"
                        style="
                          font-size: 14px;
                          margin-left: 5px;
                          cursor: pointer;
                        "
                        title="复制"
                        @click="copuAdder()"
                      ></i>
                    </span>
                  </el-col>
                </el-row>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    margin: 10px 0;

                  "
                >
                  <qr-code
                    style="height: 80px; width: 80px"
                    :qrCode="globalConfig.depositAddress"
                  ></qr-code>
                </div>
              </div>
              <div v-if="!rechargeState" style="margin-top: 10px;">
                <span style="margin: 0; display: inline-block; font-size: 12px"
                  >充值地址
                </span>
                <el-input placeholder="请输入固定的充值地址" v-model="recharge">
                </el-input>
              </div>
              <div v-if="rechargeState" style="border: 1px dashed #E0E8F6;margin-top:10px">
                <el-row :span="24">
                  <el-col :xs="24" :sm="24" :lg="24">
                    <span
                      style="
                        margin: 5px 0 5px 0;
                        width: 100%;
                        display: inline-block;
                        font-size: 12px;
                        text-align: center;
                      "
                      >充值地址
                    </span>
                  </el-col>
                  <el-col :xs="24" :sm="24" :lg="24">
                    <span
                      style="
                        font-size: 12px;
                        text-align: center;
                        display: inline-block;
                        margin: 10px 0 10px 0;
                        width: 100%;
                      "
                      >{{ recharge }}</span
                    >
                  </el-col>
                </el-row>

                <!-- <el-input
                v-if="rechargeState"
                v-model="globalConfig.depositAddress"
                readonly
                style="margin: 10px 0; height: 55px"
              > -->
                <!-- </el-input> -->
              </div>
            </div>
            <el-button
              type="warning"
              round
              style="width: calc(100% - 20px);marginTop: 30px;"
              @click="addressRecharge"
              >{{ rechargeState ? "TronLink充值" : "绑定充值地址" }}</el-button
            >
          </div>
        </div>
      </el-card>

      <div
        style="
          flex: 1;
          flex-basis: 750px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <!-- <div style="display: flex; flex-wrap: wrap"> -->
        <el-row :span="24">
          <el-col :xs="24" :sm="12">
            <el-card
              class="top-box-right-top-item"
              style="border-left: 40px solid #409eff"
            >
              <p style="font-size: 50px; font-weight: 700; color: #409eff">
                {{ userData.depositMoney }}
              </p>
              <p style="font-size: 12px">累计充值金额</p>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-card
              class="top-box-right-top-item"
              style="border-left: 40px solid #f56c6c"
            >
              <p style="font-size: 50px; font-weight: 700; color: #f56c6c">
                {{ userData.depositCount }}
              </p>
              <p style="font-size: 12px">累计充值笔数</p>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-card
              class="top-box-right-top-item"
              style="border-left: 40px solid #409eff"
            >
              <p style="font-size: 50px; font-weight: 700; color: #409eff">
                {{ userData.totalTrx }}
              </p>
              <p style="font-size: 12px">累计下单TRX</p>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12">
            <el-card
              class="top-box-right-top-item"
              style="border-left: 40px solid #67c23a"
            >
              <p style="font-size: 50px; font-weight: 700; color: #67c23a">
                {{ userData.totalCount }}
              </p>
              <p style="font-size: 12px">累计下单笔数</p>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="24">
            <el-card
              class="top-box-right-top-item"
              style="border-left: 40px solid #e6a23c"
            >
              <p style="font-size: 50px; font-weight: 700; color: #e6a23c">
                {{ userData.totalEnergy }}
              </p>
              <p style="font-size: 12px">累计能量消耗</p>
            </el-card>
          </el-col>
        </el-row>

        <!-- </div> -->
        <el-card style="border-radius: 10px; margin: 10px">
          <div style="display: flex; justify-content: space-between">
            <div>
              <p>今天订单数量</p>
              <p style="font-size: 30px; font-weight: 600">
                {{ userData.todayCount }}
              </p>
            </div>
            <div>
              <p>今天消费</p>
              <p style="font-size: 30px; font-weight: 600">
                {{ userData.todayTrx }}
              </p>
            </div>
            <div>
              <p>今天订单消耗量</p>
              <p style="font-size: 30px; font-weight: 600">
                {{ userData.todayEnergy }}
              </p>
            </div>
          </div>
        </el-card>
        <el-card style="border-radius: 10px; margin: 10px">
          <div style="display: flex; justify-content: space-between">
            <div>
              <p>昨天订单数量</p>
              <p style="font-size: 30px; font-weight: 600">
                {{ userData.yesterdayCount }}
              </p>
            </div>
            <div>
              <p>昨天消费</p>
              <p style="font-size: 30px; font-weight: 600">
                {{ userData.yesterdayTrx }}
              </p>
            </div>
            <div>
              <p>昨天订单消耗量</p>
              <p style="font-size: 30px; font-weight: 600">
                {{ userData.yesterdayEnergy }}
              </p>
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <el-card style="margin: 10px">
      <div id="lineChart" style="height: 500px; width: 100%"></div>
    </el-card>

    <el-dialog
      :modal="false"
      title="充值数量"
      :visible.sync="dialogFormVisible"
      width="300px"
    >
      <el-form :model="form">
        <el-form-item label="">
          <el-input v-model="form.number"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { apikeyAdd, userInfo, wallet, userData, dataList } from "@/api/login";
import { copy } from "@/utils/tools";
import { encryptCode } from "@/api/common.js";
import QrCode from "../qrCode.vue";
import announcement from "../announcement.vue";
import Cookies from "js-cookie";
export default {
  name: "UserHomeView",
  components: {
    QrCode,
    announcement,
  },
  props: {
    pageSize: {
      type: String,
      default: "medium",
    },
  },
  data() {
    return {
      form: {
        number: "",
      },
      dialogFormVisible: false,
      apiKey: "",
      rechargeState: false, //是否显示depositAddress
      apiKeyLoading: false,
      // 顶部滚动文字
      noticeInfo:
        "API 1小时3.2万能量 1.38TRX，6.5万能量 2.76TRX；笔数单价 2.3TRX 一笔",
      // 配置项
      configer: "",
      // 充值数量
      recharge: "",
      refreshBtnLoading: false,
      //累计下单TRX
      累计下单TRX: 0,
      累计下单笔数: 0,
      累计充值TRX: 50,
      累计充值笔数: 1,
      今天订单数量: 0,
      今天消费: 0,
      今天订单消耗量: 0,
      昨天订单数量: 0,
      昨天消费: 0,
      昨天订单消耗量: 0,
      userData: {
        totalTrx: 0, //累计下单TRX
        totalCount: 0, //累计下单笔数
        totalEnergy: 0, //累计消耗能量
        todayTrx: 0, //今天消费
        todayCount: 0, //今天订单数量
        todayEnergy: 0, //今天订单消耗量
        yesterdayTrx: 0, //昨天消费
        yesterdayCount: 0, //昨天订单数量
        yesterdayEnergy: 0, //昨天订单消耗量
        depositMoney: 0, //累计充值TRX
        depositCount: 0, //累计充值笔数
      },
    };
  },
  methods: {
    getUserData() {
      userData().then((res) => {
        let { code, data } = res.data;
        if (code == 200) {
          this.userData = data;
        }
      });
    },
    rechargeChange(val) {
      const numVal = val * 1;
      if (numVal < 50) {
        this.$message({
          type: "warning",
          message: "至少50TRX起充",
          duration: 1500
        });
      }
    },
    // 绑定钱包
    addressRecharge() {
      if (this.rechargeState) {
        this.dialogFormVisible = true;
      } else {
        if (this.recharge) {
          var data = {
            address: this.recharge,
          };
          wallet(encryptCode(data)).then((res) => {
            let { code, data } = res.data;
            if (code == 200) {
              this.rechargeState = true;
              this.$message({
                message: "绑定成功！",
                type: "success",
                duration: 1500
              });
            }
          });
        } else {
          this.$message({
            message: "请先输入绑定地址！",
            type: "warning",
          });
        }
      }
    },
    async save() {
      this.dialogFormVisible = false;
      // let obj = setInterval(async () => {
      var _this = this;
      // console.log(_this.globalConfig.depositAddress);
      if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
        // clearInterval(obj);
        let tronWeb = window.tronWeb;
        let address = tronWeb.defaultAddress.base58; //客户自己的钱包地址
        let trxResult = await tronWeb.trx.getBalance(address);
        _this.$nextTick(() => {
          let trxbalance = trxResult; //客户的钱包余额
          let amount = Math.floor(Number(_this.form.number) * 1000000); //用户输入的金额
          let toAddress = _this.globalConfig.depositAddress; //平台的收款地址
          if (trxbalance < amount) {
            //提示余额不足
            _this.$message({
              message: "余额不足！",
              type: "warning",
            });
            return;
          }

          let CONTRACT = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

          // console.log("钱包地址", address);
          // console.log("trx余额", trxbalance);

          tronWeb.transactionBuilder
            .sendTrx(toAddress, amount, address)
            .then(function (tron) {
              tronWeb.trx
                .sign(tron)
                .then(function (signs) {
                  tronWeb.trx
                    .sendRawTransaction(signs)
                    .then(function (result) {
                      if (result.result == true) {
                        _this.$message({
                          message: "操作成功！",
                          type: "success",
                        });

                        //提示操作成功
                      } else {
                        // clearInterval(obj);
                        _this.$message({
                          message: "操作失败！",
                          type: "error",
                        });
                        //提示操作失败
                      }
                    })
                    .catch(function (err) {
                      // clearInterval(obj);
                      _this.$message({
                        message: "操作失败！",
                        type: "error",
                      });
                      //提示操作失败
                    });
                })
                .catch(function (err) {
                  // clearInterval(obj);
                  _this.$message({
                    message: "操作失败！",
                    type: "error",
                  });
                  //提示操作失败
                });
            })
            .catch(function (err) {
              // clearInterval(obj);
              _this.$message({
                message: "操作失败！",
                type: "error",
              });
              //提示操作失败
            });
        });
      } else {
        // clearInterval(obj);
        _this.$message({
          message: "请先安装TronLink钱包插件!",
          type: "warning",
        });
        //提示请先安装TronLink钱包插件
      }
      // }, 10);
    },
    //生成apiKey
    apiKeySubmit() {
      if (this.apiKey) {
        copy(this.apiKey);
        // this.$message({
        //   message: "复制成功！",
        //   type: "success",
        // });
      } else {
        this.apiKeyLoading = true;
        apikeyAdd().then((res) => {
          let { code, data } = res.data;
          if (code == 200) {
            this.getUserInfo();
          }
        });
      }
    },
    getUserInfo(type) {
      userInfo().then((res) => {
        let { data } = res.data;
        let { account, address, apiKey, trx } = data;
        this.apiKey = apiKey;
        this.apiKeyLoading = false;
        this.$message({
          message: "生成成功！",
          type: "success",
        });
      });
    },
    // 刷新数据
    refreshData() {
      this.refreshBtnLoading = true;
      const that = this;
      setTimeout(() => {
        that.refreshBtnLoading = false;
      }, 2000);
    },
    getChart() {
      dataList().then((res) => {
        let { code, data } = res.data;
        if (code == 200) {
          let { time, energy } = data;
          const myChart = this.$echarts.init(
            document.getElementById("lineChart")
          );
          myChart.setOption({
            color: ["#f56c6c", "#409eff"],
            xAxis: {
              type: "category",
              data: time,
              axisLabel: {
                color: "#409eff",
              },
              boundaryGap: false,
            },
            grid: {
              left: "1%", // 也可以使用具体数值，如 50
              right: "4%",
              bottom: "3%",
              containLabel: true,
              // containLabel: true
            },
            yAxis: {
              type: "value",
              axisLabel: {
                color: "#409eff",
              },
            },
            legend: {
              type: "plain",
              data: ["能量", "带宽"],
            },
            tooltip: {
              trigger: "axis",
            },
            series: [
              {
                name: "能量",
                data: energy,
                type: "line",
              },
              // {
              //   name: "带宽",
              //   type: "line",
              //   data: [0, 0, 0, 0, 0, 0, 0],
              // },
            ],
          });
        }
      });
    },
    copuAdder() {
      copy(this.globalConfig.depositAddress);
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    globalConfig() {
      return this.$store.getters.globalConfig;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getChart();
      let { address, apiKey } = JSON.parse(Cookies.get("userInfo"));
      this.apiKey = apiKey ? apiKey : "";
      if (address) {
        this.recharge = address;
        this.rechargeState = true;
      }
      this.getUserData();
    });
  },
};
</script>

<style scoped lang="scss">
.user-home {
  font-size: 14px;
  text-align: left;
  ::v-deep .el-card__body {
    // height: calc( 100% - 40px );
  }
  .top {
    display: flex;
    height: 40px;
    line-height: 40px;
    color: #ed6a0c;
    background-color: #fffbe8;
    align-items: center;
    padding-left: 20px;
    position: relative;
  }
  .scrolling-text {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    span {
      font-size: 18px;
      display: inline-block;
      padding-left: 100%;
      animation: marquee 40s linear infinite;
    }
  }
  .top-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .top-box-right-top-item {
      border-radius: 10px;
      flex: 1 0 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px;
      text-align: center;
    }
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
